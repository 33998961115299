import { mapGetters, mapActions } from 'vuex';
import { objBlurFun } from '@/utils/index';
import router from '../router';
export default {
  data() {
    return {
      IMGURL: process.env.VUE_APP_IMG_PREFIX,
      postWidth: 232,
      postHeight: 446,
      postScale: 3,
      commercial: true,

      // 默认调试包名
      defaultPkg: `com.rhb.app`,
      // 设备信息
      deviceInfo: {},
      // 默认头像
      // 默认头像
      defaultAvatar: require('../assets/images/avatar.svg'),

      // 导航栏高度
      navBarHeight: 0,

      //显示业绩
      overallHide: '',
    };
  },
  computed: {
    ...mapGetters([`findDict`]),
    isIos() {
      const ua = navigator.userAgent;
      const isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isIos;
    },
  },
  mounted() {
    this.navBarHeight = document.querySelector(`.navbar`)?.clientHeight || 0;
    objBlurFun('textarea'); //解决textarea在ios上点击空白处键盘不收起问题
    objBlurFun('input'); //解决input在ios上点击空白处键盘不收起问题
  },
  methods: {
    ...mapActions(['posterShare']), // 分享海报
    // 设置图片域名
    setImgPrefix(url) {
      if (!url) return '';

      if (url.indexOf('http') === -1) {
        return `${process.env.VUE_APP_IMG_PREFIX}${url}`;
      } else {
        return url;
      }
    },
    // 设置链接域名
    setUrlPrefix(url) {
      if (!url) return '';

      return `${process.env.VUE_APP_IMG_PREFIX}${url}`;
    },
    // 可以传个数组，也可以传单个字符串
    // 传数组时，此方法没有返回值，会遍历数组掉接口获取字典
    // 传字符串时，返回值是type对应的字典
    /**
     * 获取字典集合
     * @param {string | array[string]} type 字典类型
     * @returns {undefined | dict} undefined或type对应的字典
     */
    async getDict(...args) {
      if (Array.isArray(args[0])) {
        for (let i = 0; i < args[0].length; i++) {
          try {
            const type = args[0][i];
            await this.$store.dispatch('dict/getDict', type);
          } catch {}
        }
        return;
      }

      return this.$store.dispatch('dict/getDict', args[0]);
    },
    numFormat(number, decimals, dec_point, thousands_sep) {
      /*
       * 参数说明：
       * number：要格式化的数字
       * decimals：保留几位小数
       * dec_point：小数点符号
       * thousands_sep：千分位符号
       * */
      //  使用： numFormat(1234567.089, 2, ".", ","); //1,234
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
          let k = Math.pow(10, prec);
          return '' + Math.floor(n * k) / k;
        };
      s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
      let re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, '$1' + sep + '$2');
      }

      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    },

    dateFormat(date, fmt) {
      let ret;
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'M+': (date.getMonth() + 1).toString(), // 月
        'D+': date.getDate().toString(), // 日
        'h+': date.getHours().toString(), // 时
        'm+': date.getMinutes().toString(), // 分
        's+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
        }
      }
      return fmt;
    },

    // 平台判断
    isIOS() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isiOS;
    },

    /**
     * 字段脱敏处理
     * @param {String} field 未脱敏字段
     * @param {Int} before 开头未脱敏字符数
     * @param {Int} after 结尾未脱敏字符数
     * @return {String} 已脱敏字段
     *
     */
    //敏感词隐藏 18507223012 => 185*****3012
    sensitiveField(field, before = 3, after = 4, num = 5) {
      if (!field) {
        return '';
      }
      field = String(field);

      // 匹配中文、英文、数字
      const regItem = '[\u4e00-\u9fa5a-zA-Z0-9.-]';
      const regExp = `(${regItem}{${before}})${regItem}*(${regItem}{${after}})`;
      const reg = new RegExp(regExp);

      let starStr = '';
      for (let i = 0; i < num; i++) {
        starStr += '*';
      }

      return field.replace(reg, `$1${starStr}$2`);
    },

    copyText(text, container) {
      // 数字没有 .length 不能执行selectText 需要转化成字符串
      const textString = text.toString();
      let wrapper = container || document.body;
      let input = document.querySelector('#copy-input');

      if (!input) {
        input = document.createElement('textarea');
        input.id = 'copy-input';
        input.readOnly = 'readOnly'; // 防止ios聚焦触发键盘事件
        input.style.position = 'absolute';
        input.style.left = '-1000px';
        input.style.zIndex = '-1000';
        wrapper.appendChild(input);
      }
      input.value = textString;
      // ios必须先选中文字且不支持 input.select();
      selectText(input, 0, textString.length);
      //console.log(document.execCommand('copy'), 'execCommand')
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        // console.log('已复制到粘贴板')
      }
      input.blur();

      // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
      // 选择文本。createTextRange(setSelectionRange)是input方法
      function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {
          //ie
          const range = textbox.createTextRange();
          range.collapse(true);
          range.moveStart('character', startIndex); //起始光标
          range.moveEnd('character', stopIndex - startIndex); //结束光标
          range.select(); //不兼容苹果
        } else {
          //firefox/chrome
          textbox.setSelectionRange(startIndex, stopIndex);
          textbox.focus();
        }
      }
    },

    // 初始化
    initAppPlugin() {
      // console.log('初始化');
      document.addEventListener('deviceready', () => {
        // 初始化中控插件
        navigator.mobileCenter.initAllPlugin();

        // 设置友盟获取deviceToken
        navigator.umeng.getDeviceToken(
          function (result) {
            // console.log('设置友盟成功===>', result);
            localStorage.setItem('deviceToken', result);
          },
          function (error) {
            console.log('设置友盟失败===>', error);
          }
        );
        navigator.umeng.addPushListener(
          function (result) {
            // console.log('result', result);
            let msgType = result.messageType;
            if (result.isSystem == 1) {
              // 系统消息
              router.push({
                name: 'messageDetail',
                query: { id: result.messageId },
              });
            } else {
              switch (msgType) {
                // 收单业务
                case '200':
                  router.push({
                    name: 'messageAcquireList',
                    query: { msgType },
                  });
                  break;

                // 办卡贷款
                case '300':
                  router.push({
                    name: 'messageNewList',
                    query: { msgType },
                  });
                  break;

                // 动账通知
                case '400':
                  router.push({
                    name: 'messageAccountList',
                    query: { msgType },
                  });
                  break;
              }
            }
          },
          function () {}
        );
        // 获取设备macAddress等敏感信息
        // console.log(device, 'device');

        var model = device.model;
        var platform = device.platform;
        var version = device.version;
        var uuid = device.uuid;
        var manufacturer = device.manufacturer;
        var serial = device.serial;
        var macAddress = device.macAddress;
        const deviceInfo = {
          macAddress: macAddress,
          manufacturer: manufacturer,
          model: model,
          osType: platform,
          osVersion: version,
          serial: serial,
          uuid: uuid,
        };
        // console.log('INITdeviceInfo', deviceInfo);
        this.deviceInfo = deviceInfo;
      });
    },

    // IOS审核账号处理
    isReviewAccount() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      return userInfo.phone == '18507223042';
    },

    // 判断是不是直营
    ifcommercial() {
      // 1805791256494915586
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo && (userInfo.platformId == '1680042643672956930' || userInfo.platformId == '1805791256494915586')) {
        this.overallHide = false;
        return false;
      } else {
        this.overallHide = true;
        return true;
      }
    },

    // 隐藏机构在线客服
    isHideContact() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      return (
        userInfo.orgId === '1539501826605514753' ||
        userInfo.orgId === '1582291729177579522' ||
        userInfo.orgId === '1593547403773566977' ||
        userInfo.orgId === '1635223162891988993'
      );
    },

    // 安卓审核账号处理
    isAndroidReviewAccount() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      return userInfo.phone == '18507223042';
    },

    // 特殊的机构办卡页面及我的页面效果不一样
    isSpecialOrg() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      return (
        userInfo.orgId === '1539501826605514753' ||
        userInfo.orgId === '1582291729177579522' ||
        userInfo.orgId === '1593547403773566977' ||
        userInfo.orgId === '1635223162891988993'
        // userInfo.orgId === '1654770116101545986' ||
        // userInfo.orgId === '1680149948586422274' ||
        // userInfo.orgId === '1680075771837235201' ||
        // userInfo.orgId === '1672150480867377154' ||
        // userInfo.orgId === '1681633824693420034' ||
        // userInfo.orgId === '1681633218347425793' ||
        // userInfo.orgId === '1681632649961144321' ||
        // userInfo.orgId === '1681630994460966913' ||
        // userInfo.orgId === '1681629298728394754' ||
        // userInfo.orgId === '1681628720128983041' ||
        // userInfo.orgId === '1681628158322302978' ||
        // userInfo.orgId === '1681627574752641026' ||
        // userInfo.orgId === '1681623248763641858' ||
        // userInfo.orgId === '1681622061537165313' ||
        // userInfo.orgId === '1681620510025736194' ||
        // userInfo.orgId === '1681619630643765250' ||
        // userInfo.orgId === '1681600624990486529' ||
        // userInfo.orgId === '1681639285861146625' ||
        // userInfo.orgId === '1681636064451756033' ||
        // userInfo.orgId === '1681635580805931010' ||
        // userInfo.orgId === '1681635046452232193' ||
        // userInfo.orgId === '1681634443830779905' ||
        // userInfo.orgId === '1684037942115831809' ||
        // userInfo.orgId === '1684464836540620801'
      );
    },

    // 是否瑞升达平台
    isRsdPlat() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      return userInfo.platformId === '1516293377277214722';
    },

    // 获取未读消息数量
    async getUnReadMessageNum() {
      const { data } = await this.$api.message.message_group(false);
      let numTotal = 0;
      data.data.forEach(item => {
        numTotal += item.unRead;
      });
      numTotal = numTotal > 99 ? 99 : numTotal;
      numTotal = numTotal == 0 ? '' : numTotal;
      this.$store.commit('SET_UNREAD_MESSAGE_NUM', numTotal);
      navigator.jhbUtils?.setBadge(
        function (success) {
          console.log('setBadge success');
        },
        function (err) {
          console.log('setBadge', err);
        },
        numTotal == '' ? 0 : numTotal
      );
    },

    // 计算
    calcRateShow(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).div(1000000);
    },

    calcRateCommit(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).mul(1000000);
    },
    //前端界面价格显示 乘以100
    calcPriceShow(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).div(100);
    },
    //提交后端 除以100
    calcPriceCommit(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).mul(100);
    },

    calcPointsShow(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).div(1000);
    },

    calcPointsCommit(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).mul(1000);
    },

    calcTenThousandShow(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).div(10000);
    },

    calcTenThousandCommit(a) {
      a = a ?? 0;
      return a === 0 ? 0 : Number(a).mul(10000);
    },
    // 格式化数字 如1000 => 1,000
    toThousands(num) {
      var tag = (num || 0).toString().split('.');
      tag[0] = tag[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return tag.join('.');
    },

    callPhone(num) {
      // 利用中控插件打电话
      navigator.dialing.dial(
        function success(str) {
          console.log('success===>>>', str);
        },
        function fail(error) {
          console.log('fail===>>>', error);
        },
        num,
        true
      );
    },

    /**
     * @description - 根据时间及类型将时间转换成YYYY年MM月DD日
     * @param {String} [time]    - 当前时间字符串或者时间对象
     * @param {String} [type]    - 类型
     * @return void
     **/
    dateFormatYYYMM(time, type) {
      const dateStr = type === 200 || type === '200' ? 'YYYY年MM月DD日' : 'YYYY年MM月';
      return this.$moment(time).format(dateStr);
    },

    /**
     * @description - app配置轮播图跳转
     * @param {Object} [data]    - 跳转数据
     * @return void
     **/
    handleGoDetail(data) {
      // jumpType 100仅展示 200跳转链接 300自定义
      // urlType 100=外部链接 200=内部链接
      if (!data || data.jumpType == 100) return;
      if (data.jumpType == 200) {
        if (data.urlType === 100) {
          const web = navigator.WebPlugin || navigator.Web;
          var options = { url: data.jumpUrl, title: `网页` };
          web.openWeb(
            result => {
              console.log(`成功打开`, result);
            },
            error => {
              console.log(`err==>`, error);
            },
            options
          );
        } else if (data.urlType === 200) {
          this.$router.push({ path: data.jumpUrl });
        }
      } else if (data.jumpType == 300) {
        // 自定义页面
        this.$router.push({
          name: `pageDetail`,
          query: {
            id: data.id,
          },
        });
      }
    },
  },
};
