export default [
  {
    path: '/business',
    name: 'business',
    redirect: '/business/index',
    component: {
      name: 'BusinessWrapper',
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'business',
        component: () => import('@/views/business'),
      },
      {
        path: 'detail',
        name: 'tradeDetail',
        component: () => import('@/views/business/detail'),
      },
      {
        path: 'businessDetail',
        name: 'businessDetail',
        component: () => import('@/views/business/businessDetail'),
      },
      {
        path: 'proxyDetail',
        name: 'proxyDetail',
        component: () => import('@/views/business/proxyDetail'),
      },
      {
        path: 'finalDetail',
        name: 'finalDetail',
        component: () => import('@/views/business/finalDetail'),
      },
      {
        path: 'tradeList',
        name: 'tradeList',
        component: () => import('@/views/business/tradeList'),
      },
      {
        path: 'businessList',
        name: 'businessList',
        component: () => import('@/views/business/businessList'),
      },
      {
        path: 'proxyList',
        name: 'proxyList',
        component: () => import('@/views/business/proxyList'),
      },
      {
        path: 'invite',
        name: 'invite',
        component: () => import('@/views/business/invite'),
      },
      {
        path: 'assistant',
        name: 'assistant',
        component: () => import('@/views/business/assistant'),
      },
      {
        path: 'postCenter',
        name: 'postCenter',
        component: () => import('@/views/business/assistant/postCenter'),
      },
      {
        path: 'postShare',
        name: 'postShare',
        component: () => import('@/views/business/assistant/postShare'),
      },
      {
        path: 'cardSetting',
        name: 'cardSetting',
        component: () => import('@/views/business/assistant/cardSetting'),
      },
      //授权
      {
        path: 'authorization',
        name: 'authorization',
        component: () => import('@/views/authorization/index'),
      },
      {
        path: 'authAtjdwh',
        name: 'authAtjdwh',
        component: () => import('@/views/authorization/jdwh.vue'),
      },
      
    ],
  },
];
