export default [
  {
    path: '/dxm',
    // name: 'SIMcard',
    redirect: '/dxm/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'dxm',
        component: () => import('@/views/dxm'),
      },
      {
        path: 'dxmShare',
        name: 'dxmShare',
        component: () => import('@/views/dxm/dxmShare.vue'),
      },
      {
        path: 'testView',
        name: 'testView',
        component: () => import('@/views/testView/index.vue'),
      },
    ],
  },
];
